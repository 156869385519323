/* Format the login form */
.login-form {
  margin: 0 auto;
  width: 300px;
  padding: 10vh 1em 1em 10vh;
}

.form {
  margin: 0 auto;
  width: 300px;
  padding: 10vh 1em 1em 10vh;

}

.dashboard {
  margin: 0 auto;
  width: 300px;
  padding: 10vh 1em 1em 10vh;
}